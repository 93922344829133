import React from 'react';
import { navigate } from 'gatsby';
import { useKeycloak } from 'react-keycloak';

const IndexPage = () => {
  const [initialized] = useKeycloak();
  // Keycloak validation
  if (!initialized) {
    return <div>Loading...</div>;
  }

  navigate('/dashboard');

  return null;
};

export default IndexPage;
